import React from "react"
import { PageLayout } from "../components/Global/Layouts"
import { SiteMetadata } from "../components/Shared"

const ErrorPage = () => (
  <PageLayout>
    <SiteMetadata title={"Not Founds"} description={"Page not found ]:"} />
    <div className="py-12 lg:pb-128 relative">
      <div className="bg-white absolute -right-full -left-full inset-y-0" />
      <div className="w-full md:w-1/2 xl:w-full z-10 relative">
        <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-600 sm:text-4xl mb-4">
          Oops!
        </h1>
          <div>
            <div className="flex flex-row items-center justify-start">
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-annmade-1.0-blue sm:text-2xl">
                We can't seem to find the page you're looking for
              </h2>
            </div>
          </div>
      </div>
    </div>
  </PageLayout>
)

export default ErrorPage
